<template>
  <modal :show="showModal" modal-classes="modal-secondary" size="lg">
    <div>
      <tabs
        fill
        class="flex-column flex-md-row"
        tabNavWrapperClasses="nav-wrapper"
        tabNavClasses="nav nav-pills nav-fill"
        value="global"
      >
        <tab-pane title="global" id="1" :active="true">
          <span slot="title">
            {{ $t("FILES.HISTORY") }}
          </span>
          <card header-classes="bg-transparent" v-if="fileData">
            <h3 slot="header" class="mb-0">{{ $t("FILES.FILE_HISTORY") }}</h3>

            <time-line type="one-side">
              <time-line-item
                badge-type="success"
                badge-icon="ni ni-bold-right"
              >
                <small class="text-muted font-weight-bold">
                  {{ $formatDate(fileData.updated_at) }}
                </small>
                <h5 class="mt-3 mb-0">{{ fileData.name }}</h5>
                <p class="text-sm mt-1 mb-0">
                  <a :href="fileData.url">{{ fileData.url }}</a>
                </p>
                <div class="mt-3">
                  <badge rounded type="info">
                    {{ fileData.human_readable_size }}
                  </badge>
                </div>
              </time-line-item>
            </time-line>

            <time-line
              type="one-side"
              v-for="(item, index) in fileData.file_history"
              :key="index"
            >
              <time-line-item
                badge-type="success"
                badge-icon="ni ni-bold-right"
              >
                <small class="text-muted font-weight-bold">
                  {{ $formatDate(item.updated_at) }}
                </small>
                <h5 class="mt-3 mb-0">{{ item.name }}</h5>
                <p class="text-sm mt-1 mb-0">
                  <a :href="item.url">{{ item.url }}</a>
                </p>
                <div class="mt-3">
                  <badge rounded type="info">
                    {{ item.human_readable_size }}
                  </badge>
                </div>
              </time-line-item>
            </time-line>
          </card>
        </tab-pane>
        <tab-pane
          title="global"
          id="2"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOGS)"
        >
          <span slot="title">
            {{ $t("COMMON.LOGS") }}
          </span>
          <log-list-table :filterSubject="fileData" />
        </tab-pane>
      </tabs>
    </div>
    <template slot="footer">
      <button type="button" class="btn btn-link ml-auto" @click="closeModal()">
        {{ $t("COMMON.CLOSE") }}
      </button>
    </template>
  </modal>
</template>

<script>
import { Button } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import { TimeLine, TimeLineItem, Tabs, TabPane } from "@/components";
import LogListTable from "@/views/Pages/DefaultModule/LogManagement/partials/LogListTable.vue";

export default {
  components: {
    Tabs,
    TabPane,
    TimeLine,
    TimeLineItem,
    LogListTable,
    [Button.name]: Button,
  },

  mixins: [formMixin],

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    fileData: {
      type: Object,
      default: null,
      description: "File Data",
    },
  },

  computed: {},

  data() {
    return {};
  },

  methods: {
    closeModal(refresh = false) {
      this.$emit("onCloseModal", refresh);
    },
  },

  watch: {},
};
</script>
