<template>
  <modal
    :show="showModal"
    modal-classes="modal-secondary add-file-dialog"
    size="lg"
  >
    <div class="elite-tabs-wrapper">
      <tabs
        fill
        class="flex-column flex-md-row"
        tabNavWrapperClasses="nav-wrapper"
        tabNavClasses="nav elite-tabs"
        value="global"
      >
        <tab-pane title="global" id="1" :active="true">
          <span slot="title">
            {{ $t("FILES.FILE") }}
          </span>

          <dropzone-file-upload
            :url="uploadUrl"
            @addedfile="
              () => {
                loading = true;
              }
            "
            multiple
            @queuecomplete="filesUpdated"
            @removedfile="filesUpdated"
            @error="onUploadError"
          >
          </dropzone-file-upload>
        </tab-pane>

        <tab-pane
          title="global"
          id="2"
          v-if="$currentUserCan($permissions.PERM_MANAGE_ACCESS_FILES)"
        >
          <span slot="title">
            {{ $t("FILES.SHARE") }}
          </span>

          <!-- Locations -->
          <base-input
            :label="`${$t('COMMON.LOCATIONS')}`"
            :placeholder="$t('COMMON.LOCATIONS')"
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)"
          >
            <locations-selector
              :locations="fileRestrictions.allowedLocations"
              :filterable="true"
              :showAll="false"
              :disabled="!fileRestrictions.organization.id"
              @locationsChanged="
                (locations) => {
                  fileRestrictions.allowedLocations = locations;
                }
              "
            />
          </base-input>
          <validation-error :errors="apiValidationErrors.organization" />

          <base-input
            :label="`${$t('COMMON.ROLES')}`"
            :placeholder="$t('COMMON.ROLES')"
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ROLES)"
          >
            <roles-selector
              :roles="fileRestrictions.roles"
              :filterable="true"
              :filterOrganization="fileRestrictions.organization.id"
              :disabled="!fileRestrictions.organization.id"
              @rolesChanged="
                (roles) => {
                  fileRestrictions.roles = roles;
                }
              "
            />
          </base-input>
          <validation-error :errors="apiValidationErrors.roles" />

          <base-input
            :label="`${$t('COMMON.USERS')}`"
            :placeholder="$t('COMMON.USERS')"
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_USERS)"
          >
            <users-selector
              :users="fileRestrictions.users"
              :filterable="true"
              :filterOrganization="fileRestrictions.organization.id"
              :disabled="!fileRestrictions.organization.id"
              @usersChanged="
                (users) => {
                  fileRestrictions.users = users;
                }
              "
            />
          </base-input>
          <validation-error :errors="apiValidationErrors.users" />
        </tab-pane>
      </tabs>
    </div>
    <template slot="footer">
      <button
        type="submit"
        class="btn add"
        @click="addFiles"
        :disabled="loading"
      >
        <span v-if="loading" class="btn-inner--icon">
          <i class="fas fa-spinner fa-spin"></i>
        </span>
        {{ $t("FILES.ADD_FILE") }}
      </button>

      <button
        type="button"
        class="btn btn-link ml-auto close"
        @click="closeModal()"
        :disabled="loading"
      >
        {{ $t("COMMON.CLOSE") }}
      </button>
    </template>
  </modal>
</template>

<script>
import swal from "sweetalert2";
import { cloneDeep } from "lodash";
import { Button } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { Tabs, TabPane } from "@/components";
import ValidationError from "@/components/ValidationError.vue";
import LocationsSelector from "@/components/LocationsSelector.vue";
import RolesSelector from "@/components/RolesSelector.vue";
import UsersSelector from "@/components/UsersSelector.vue";
import DropzoneFileUpload from "@/components/Inputs/DropzoneFileUpload";

const apiUrl = process.env.VUE_APP_API_BASE_URL;

export default {
  components: {
    Tabs,
    TabPane,
    ValidationError,
    LocationsSelector,
    LocationsSelector,
    RolesSelector,
    UsersSelector,
    DropzoneFileUpload,
    [Button.name]: Button,
  },

  mixins: [formMixin, requestErrorMixin],

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    currentFolder: {
      type: Object,
      default: null,
      description: "Current file",
    },
  },

  computed: {
    uploadUrl() {
      return `${apiUrl}/uploads/folders/${this.currentFolder.id}/files`;
    },
  },

  data() {
    return {
      loading: false,
      fileRestrictions: {
        organization: {
          type: "organizations",
          id: this.currentFolder.organization.id,
        },
        allowedLocations: this.currentFolder.allowedLocations.map((item) => ({
          type: "locations",
          id: item.id,
        })),
        tags: this.currentFolder.tags.map((item) => ({
          type: "tags",
          id: item.id,
        })),
        users: this.currentFolder.users.map((item) => ({
          type: "users",
          id: item.id,
        })),
        roles: this.currentFolder.roles.map((item) => ({
          type: "roles",
          id: item.id,
        })),
      },
      files: [],
    };
  },

  methods: {
    closeModal(refresh = false) {
      this.$emit("onCloseModal", refresh);
    },

    async addFiles() {
      this.loading = true;

      /** check if the files to upload does not have duplicated names */
      for (const file of this.files) {
        const existingFolderFile = this.currentFolder.files.find((item) => {
          return item.name.toLowerCase() === file.name.toLowerCase();
        });
        const existingUploadedFiles = !!this.files.find(
          (item) => item.name == file.name && item.id != file.id
        );
        if (existingFolderFile || existingUploadedFiles) {
          await swal.fire({
            title: this.$t(`FILES.FILE_EXISTS_WITH_SAME_NAME_X`, {
              filename: file.name,
            }),
            icon: "error",
            confirmButtonText: this.$t("COMMON.OK"),
            confirmButtonClass: "btn btn-primary",
            cancelButtonClass: "btn btn-warning",
          });
          this.loading = false;
          return;
        }
      }

      for (const file of this.files) {
        let fileData = cloneDeep(file);
        fileData = { ...fileData, ...this.fileRestrictions };
        fileData.folder = {
          type: "folders",
          id: this.currentFolder.id,
        };
        fileData.type = "files";
        fileData.relationshipNames = [
          "folders",
          "organization",
          "tags",
          "allowedLocations",
          "roles",
          "users",
          "folder",
        ];

        try {
          await this.$store.dispatch("files/add", fileData);
          this.$notify({
            type: "success",
            message: this.$t("FILES.FILE_ADDED"),
          });
        } catch (error) {
          await this.showRequestError(error);
        }
      }
      this.loading = false;
      this.closeModal(true);
    },

    filesUpdated(data, files) {
      this.files = [];
      for (const file of files.filter((item) => item.status === "success")) {
        const fileParts = file.name.split(".");
        const fileData = {
          file_extension: fileParts.pop(),
          file_name: fileParts.join("."),
          path: JSON.parse(file.xhr.response).path,
          name: file.name,
        };
        this.files.push(fileData);
      }
      this.loading = false;
    },

    async onUploadError(error) {
      if (error.status === "error") {
        if (error.xhr) {
          let errorMessage = this.$t("ERRORS.FILE_UPLOAD_ERROR");
          if (error.xhr.response) {
            errorMessage = error.xhr.response;
          } else {
            errorMessage = error.xhr.statusText;
          }
          await swal.fire({
            title: this.$t(`ERRORS.FILE_UPLOAD_ERROR`),
            html: errorMessage,
            icon: "error",
            confirmButtonText: this.$t("COMMON.OK"),
            confirmButtonClass: "btn btn-primary",
            cancelButtonClass: "btn btn-warning",
          });
        }
      }
      this.loading = false;
    },
  },

  watch: {
    currentFolder: {
      handler(currentFolder) {},
      deep: true,
    },
  },
};
</script>
