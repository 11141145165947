var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{attrs:{"show":_vm.showModal,"modal-classes":"modal-secondary","size":"lg"}},[_c('div',[_c('tabs',{staticClass:"flex-column flex-md-row",attrs:{"fill":"","tabNavWrapperClasses":"nav-wrapper","tabNavClasses":"nav nav-pills nav-fill","value":"global"}},[_c('tab-pane',{attrs:{"title":"global","id":"1","active":true}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v(" "+_vm._s(_vm.$t("FILES.FILE"))+" ")]),(_vm.fileData)?_c('div',{staticClass:"col-12 text-center display-4 mb-2"},[_c('a',{staticClass:"col-12",attrs:{"href":_vm.fileData.url,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.fileData.name)+" ")])]):_vm._e(),_c('dropzone-file-upload',{key:_vm.renderKey,attrs:{"url":_vm.uploadUrl},on:{"addedfile":() => {
              _vm.loading = true;
            },"queuecomplete":_vm.fileUpdated,"removedfile":_vm.fileUpdated,"error":_vm.onUploadError}}),_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.NAME')}`,"placeholder":`${_vm.$t('COMMON.NAME')}`},model:{value:(_vm.file.file_name),callback:function ($$v) {_vm.$set(_vm.file, "file_name", $$v)},expression:"file.file_name"}}),_c('base-input',{attrs:{"label":_vm.$t('COMMON.EXCERPT'),"placeholder":_vm.$t('COMMON.EXCERPT')}},[_c('html-editor',{model:{value:(_vm.file.excerpt),callback:function ($$v) {_vm.$set(_vm.file, "excerpt", $$v)},expression:"file.excerpt"}})],1)],1),(_vm.$currentUserCan(_vm.$permissions.PERM_MANAGE_ACCESS_FILES))?_c('tab-pane',{attrs:{"title":"global","id":"2"}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v(" "+_vm._s(_vm.$t("FILES.SHARE"))+" ")]),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_LOCATIONS))?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.LOCATIONS')}`,"placeholder":_vm.$t('COMMON.LOCATIONS')}},[_c('locations-selector',{attrs:{"locations":_vm.file.allowedLocations,"filterable":true,"showAll":false,"disabled":!_vm.file.organization.id},on:{"locationsChanged":(locations) => {
                _vm.file.allowedLocations = locations;
              }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.organization}}),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ROLES))?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.ROLES')}`,"placeholder":_vm.$t('COMMON.ROLES')}},[_c('roles-selector',{attrs:{"roles":_vm.file.roles,"filterable":true,"filterOrganization":_vm.file.organization.id,"disabled":!_vm.file.organization.id},on:{"rolesChanged":(roles) => {
                _vm.file.roles = roles;
              }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.roles}}),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_USERS))?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.USERS')}`,"placeholder":_vm.$t('COMMON.USERS')}},[_c('users-selector',{attrs:{"users":_vm.file.users,"filterable":true,"filterOrganization":_vm.file.organization.id,"disabled":!_vm.file.organization.id},on:{"usersChanged":(users) => {
                _vm.file.users = users;
              }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.users}})],1):_vm._e()],1)],1),_c('template',{slot:"footer"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit","disabled":_vm.loading},on:{"click":_vm.editFile}},[(_vm.loading)?_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"fas fa-spinner fa-spin"})]):_vm._e(),_vm._v(" "+_vm._s(_vm.$t("FILES.EDIT_FILE"))+" ")]),_c('button',{staticClass:"btn btn-link ml-auto",attrs:{"type":"button","disabled":_vm.loading},on:{"click":function($event){return _vm.closeModal()}}},[_vm._v(" "+_vm._s(_vm.$t("COMMON.CLOSE"))+" ")])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }