<template>
  <modal :show="showModal" modal-classes="modal-secondary" size="lg">
    <div>
      <tabs
        fill
        class="flex-column flex-md-row"
        tabNavWrapperClasses="nav-wrapper"
        tabNavClasses="nav nav-pills nav-fill"
        value="global"
      >
        <tab-pane title="global" id="1" :active="true">
          <span slot="title">
            {{ $t("FILES.FILE") }}
          </span>

          <div v-if="fileData" class="col-12 text-center display-4 mb-2">
            <a :href="fileData.url" target="_blank" class="col-12">
              {{ fileData.name }}
            </a>
          </div>

          <dropzone-file-upload
            :key="renderKey"
            :url="uploadUrl"
            @addedfile="
              () => {
                loading = true;
              }
            "
            @queuecomplete="fileUpdated"
            @removedfile="fileUpdated"
            @error="onUploadError"
          >
          </dropzone-file-upload>

          <base-input
            v-model="file.file_name"
            :label="`${$t('COMMON.NAME')}`"
            :placeholder="`${$t('COMMON.NAME')}`"
          >
          </base-input>

          <!-- Excerpt -->
          <base-input
            :label="$t('COMMON.EXCERPT')"
            :placeholder="$t('COMMON.EXCERPT')"
          >
            <html-editor v-model="file.excerpt"> </html-editor>
          </base-input>
        </tab-pane>

        <tab-pane
          title="global"
          id="2"
          v-if="$currentUserCan($permissions.PERM_MANAGE_ACCESS_FILES)"
        >
          <span slot="title">
            {{ $t("FILES.SHARE") }}
          </span>

          <!-- Locations -->
          <base-input
            :label="`${$t('COMMON.LOCATIONS')}`"
            :placeholder="$t('COMMON.LOCATIONS')"
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)"
          >
            <locations-selector
              :locations="file.allowedLocations"
              :filterable="true"
              :showAll="false"
              :disabled="!file.organization.id"
              @locationsChanged="
                (locations) => {
                  file.allowedLocations = locations;
                }
              "
            />
          </base-input>
          <validation-error :errors="apiValidationErrors.organization" />

          <base-input
            :label="`${$t('COMMON.ROLES')}`"
            :placeholder="$t('COMMON.ROLES')"
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ROLES)"
          >
            <roles-selector
              :roles="file.roles"
              :filterable="true"
              :filterOrganization="file.organization.id"
              :disabled="!file.organization.id"
              @rolesChanged="
                (roles) => {
                  file.roles = roles;
                }
              "
            />
          </base-input>
          <validation-error :errors="apiValidationErrors.roles" />

          <base-input
            :label="`${$t('COMMON.USERS')}`"
            :placeholder="$t('COMMON.USERS')"
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_USERS)"
          >
            <users-selector
              :users="file.users"
              :filterable="true"
              :filterOrganization="file.organization.id"
              :disabled="!file.organization.id"
              @usersChanged="
                (users) => {
                  file.users = users;
                }
              "
            />
          </base-input>
          <validation-error :errors="apiValidationErrors.users" />
        </tab-pane>
      </tabs>
    </div>
    <template slot="footer">
      <button
        type="submit"
        class="btn btn-primary"
        @click="editFile"
        :disabled="loading"
      >
        <span v-if="loading" class="btn-inner--icon">
          <i class="fas fa-spinner fa-spin"></i>
        </span>
        {{ $t("FILES.EDIT_FILE") }}
      </button>

      <button
        type="button"
        class="btn btn-link ml-auto"
        @click="closeModal()"
        :disabled="loading"
      >
        {{ $t("COMMON.CLOSE") }}
      </button>
    </template>
  </modal>
</template>

<script>
import swal from "sweetalert2";
import { cloneDeep } from "lodash";
import { Button } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { Tabs, TabPane } from "@/components";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import ValidationError from "@/components/ValidationError.vue";
import LocationsSelector from "@/components/LocationsSelector.vue";
import RolesSelector from "@/components/RolesSelector.vue";
import UsersSelector from "@/components/UsersSelector.vue";
import DropzoneFileUpload from "@/components/Inputs/DropzoneFileUpload";

const apiUrl = process.env.VUE_APP_API_BASE_URL;

export default {
  components: {
    Tabs,
    TabPane,
    HtmlEditor,
    ValidationError,
    LocationsSelector,
    LocationsSelector,
    RolesSelector,
    UsersSelector,
    DropzoneFileUpload,
    [Button.name]: Button,
  },

  mixins: [formMixin, requestErrorMixin],

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    fileData: {
      type: Object,
      default: null,
      description: "File Data",
    },
    currentFolder: {
      type: Object,
      default: null,
      description: "Current file",
    },
  },

  computed: {
    uploadUrl() {
      return `${apiUrl}/uploads/folders/${this.currentFolder.id}/files`;
    },
  },

  data() {
    return {
      loading: false,
      file: cloneDeep(this.fileData),
      renderKey: 0,
    };
  },

  methods: {
    closeModal(refresh = false) {
      this.$emit("onCloseModal", refresh);
    },

    async editFile() {
      this.loading = true;
      const fileData = cloneDeep(this.file);
      fileData.name = `${fileData.file_name}.${fileData.file_extension}`;

      const existingFile = this.currentFolder.files.find((item) => {
        return (
          item.name.toLowerCase() === fileData.name.toLowerCase() &&
          item.id !== fileData.id
        );
      });
      if (existingFile) {
        await swal.fire({
          title: this.$t(`FILES.FILE_EXISTS_WITH_SAME_NAME`),
          icon: "error",
          confirmButtonText: this.$t("COMMON.OK"),
          confirmButtonClass: "btn btn-primary",
          cancelButtonClass: "btn btn-warning",
        });
        this.loading = false;
        return;
      }

      try {
        await this.$store.dispatch("files/update", fileData);
        this.$notify({
          type: "success",
          message: this.$t("FILES.FILE_UPDATED"),
        });
        this.closeModal(true);
        this.loading = false;
      } catch (error) {
        this.showRequestError(error);
        this.loading = false;
      }
    },

    fileUpdated(data, files) {
      if (files.length > 0) {
        const file = files[0];
        if (file.status === "success") {
          const fileParts = file.name.split(".");
          this.file.file_extension = fileParts.pop();
          this.file.file_name = fileParts.join(".");
          this.file.path = JSON.parse(file.xhr.response).path;
          this.file.name = `${this.file.file_name}.${this.file.file_extension}`;
        }
      }
      this.loading = false;
    },

    async onUploadError(error) {
      if (error.status === "error") {
        if (error.xhr) {
          let errorMessage = this.$t("ERRORS.FILE_UPLOAD_ERROR");
          if (error.xhr.response) {
            errorMessage = error.xhr.response;
          } else {
            errorMessage = error.xhr.statusText;
          }
          await swal.fire({
            title: this.$t(`ERRORS.FILE_UPLOAD_ERROR`),
            html: errorMessage,
            icon: "error",
            confirmButtonText: this.$t("COMMON.OK"),
            confirmButtonClass: "btn btn-primary",
            cancelButtonClass: "btn btn-warning",
          });
        }
      }
      this.loading = false;
    },
  },

  watch: {
    fileData: {
      handler(fileData) {
        this.file = cloneDeep(this.fileData);
      },
      deep: true,
    },

    showModal(showModal) {
      this.renderKey = this.renderKey + 1;
    },
  },
};
</script>
